@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: theme("colors.blue.300");
  --font: theme("fontFamily.body");
}

body {
  background-color: var(--primary);
  font-family: var(--font);
}
